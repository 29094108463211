
<script>
import Repl from "./Repl.vue";
import Resizer from './Resizer.vue';

export default {
  components: { Repl, Resizer },
  props: {
    output: String,
  },
  name: "ConsoleOutput",
  watch: {
    output() {
      this.scrollToBottom();
    },
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const content = this.$refs.consoleOutput;
        content.scrollTop = content.scrollHeight;
      });
    },
    clearOutput() {
      this.$emit('clearOutput');
    },
    clearConsole() {
      this.$refs.repl.clearConsole();
    }
  }
}
</script>

<template>
  <div class="bottom-pane">
    <div class="bottom-pane__splitter">
      <div class="bottom-pane__container bottom-pane__container--console">
        <div class="bottom-pane__label">
          <span>Console</span>
          <button class="bottom-pane__clear-btn" v-on:click="clearConsole()">
            <img src="../assets/bin-gold.svg" alt="Clear console" class="bottom-pane__clear-icon" height="15" width="15" />
          </button>
        </div>
        <repl ref="repl"/>
      </div>
      <resizer :direction="'horizontal'" />
      <div class="bottom-pane__container bottom-pane__container--output">
        <div class="bottom-pane__label">
          <span>Output</span>
          <button class="bottom-pane__clear-btn" @click="clearOutput()">
            <img src="../assets/bin-purple.svg" alt="Clear output" class="bottom-pane__clear-icon" height="15" width="15" />
          </button>
        </div>
        <textarea ref="consoleOutput" readonly :value="output || 'No output yet'" class="bottom-pane__output" rows="10"></textarea>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.bottom-pane {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  &__splitter {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 600;
    height: 100%;
    position: relative;

    &--console {
      color: var(--color-gold);
      min-width: 15rem;
      width: 50%;
    }

    &--output {
      color: var(--color-light-purple);
      min-width: 15rem;
      flex: 1;
    }
  }

  &__label {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 2rem;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: -2rem;
    width: 100%;

    span {
      margin-left: 1rem;
    }
  }

  &__clear-btn {
    background-color: transparent;
    border: none;
    height: 1.5rem;
    margin: 0 1rem;
    padding: 0;
    pointer-events: all;
  }

  &__clear-icon {
    height: 1.5rem;
    object-fit: contain;
    width: 1.5rem;
  }

  &__output {
    background-color: var(--color-light-purple);
    border: none;
    color: var(--color-purple);
    font-size: 1.4rem;
    font-weight: 500;
    height: 100%;
    margin: 0;
    outline: none;
    overflow-x: hidden;
    padding-left: 1rem;
    resize: none;
    width: 100%;
  }

  .repl-body {
    background-color: var(--color-gold);
    height: 100%;
    width: 100%;
  }
}

.accent {
  color: var(--color-alternative);
}
</style>

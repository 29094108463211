# A Vue component to execute python code in a textara using Pyodide.
<template>  
    <div id="pyodide">
        <textarea hidden v-model="code" rows="10" cols="50"></textarea>
<!--
        <br/>
        <button @click="runCode">Run Code</button>
        <button @click="runCode2">Run Code</button>
-->
        <template v-if="!pyodideLoaded">
        <!--<<p>Loading</p>-->
<!--
          <loading msg="Loading pyodide..." />
-->
        </template>
        <template v-else>
          <!--<p>Pyodide loaded!</p>-->
        </template>
        <textarea hidden v-model="execution_output" rows="10" cols="50"></textarea>
    </div>
</template>
<script>
    import python_version from "raw-loader!../assets/python_version.py";
    import { loadPyodide } from 'pyodide'; 
    // import { eventBus } from './main';
    import Vue from "vue";

    export default {
      name: "Pyodide",
      data() {
        return {
          code: "print('Hello World')",
          errorMsg: "No error",
          pyodideLoaded: false,
          execution_output: "Output here!",
          pyodide: null
        };
      },
    async mounted() {
        console.log("mounted() start.");
        await this.initializePyodide();
        this.runTestCommand();
        console.log("mounted() done.");
    },
      methods: {
          runCode2: function(){
                console.log("runCode2 start");
                console.log("this.code:"+this.code);
                this.execution_output=this.runPython(this.code);
                console.log("runCode2 end");
            },
           runPython: function(code) {
              // run code
              console.log("runPython start");
              console.log(code)
              this.pyodide.globals.set("code_to_run",code);
              var output=this.pyodide.runPython('run_code(code_to_run)');
              console.log(output)
              console.log("runPython end");
              return output;
           },
           runTestCommand: function () {
              console.log(this.pyodide.runPython(python_version));
           },
           initializePyodide: async function () {
                console.log("initializePyodide - start");
                // global loadPyodide
             try {
                console.log("initializePyodide - about to load pyodide.js with Vue.loadScript()");
                await Vue.loadScript(
                  "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/pyodide.js"
                );
                console.log("initializePyodide - loaded pyodide.js with Vue.loadScript()");

                console.log("initializePyodide - about to load https://cdn.jsdelivr.net/pyodide/v0.21.3/full/ with Vue.loadScript()");
                this.pyodide = await loadPyodide({
                  indexURL: "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/",
                });

                var setup_code = `
                import sys, io, traceback
                namespace = {}  # use separate namespace to hide run_code, modules, etc.

                def run_code(code):
                  """run specified code and return stdout and stderr"""
                  out = io.StringIO()
                  oldout = sys.stdout
                  olderr = sys.stderr
                  sys.stdout = sys.stderr = out
                  try:
                      # change next line to exec(code, {}) if you want to clear vars each time
                      exec(code, namespace)
                  except:
                      traceback.print_exc()

                  sys.stdout = oldout
                  sys.stderr = olderr
                  return out.getvalue()
                  `
             console.log("Before: pyodide.runPython(setup_code) ...\n");
             this.pyodide.runPython(setup_code);
             console.log("After: pyodide.runPython(setup_code) ...\n");



              // load pandas lib
                await this.pyodide.loadPackage(["matplotlib", "numpy", "pandas", "scikit-learn","micropip","shapely"]);

                this.pyodideLoaded = true;
             } catch (error) {
                console.log("initializePyodide - caught error");
                this.errorMsg = error;
                console.error(error);
            }
                console.log("initializePyodide - end");
           },

      //   getCode(){
      //       console.log("getCode() called");
      //       eventBus.$emit('get_code');
      //       console.log("getCode() done.");
      //   },
        async runCode() {
            console.log("runCode() called.");
            await this.pyodide.loadPackagesFromImports(this.code);
            this.pyodide.runPython(this.code);
            console.log("runCode() done");
        },
      }
    };


</script>

<template>
  <div class="resizer" :data-direction="direction" ref="resizer" @mousedown="mouseDownHandler"/>
</template>

<script>
export default {
  name: 'Resizer',
  props: {
    direction: String,
  },
  data() {
    return {
      nextSibling: null,
      prevSibling: null,
      prevSiblingHeight: 0,
      prevSiblingWidth: 0,
      x: 0,
      y: 0,
    }
  },
  mounted() {
    this.prevSibling = this.$refs.resizer.previousElementSibling;
    this.nextSibling = this.$refs.resizer.nextElementSibling;
  },
  methods: {
    mouseDownHandler(e){
      // Get the current pointer position
      this.x = e.clientX;
      this.y = e.clientY;

      const rect = this.prevSibling.getBoundingClientRect();
      this.prevSiblingHeight = rect.height;
      this.prevSiblingWidth = rect.width;
      // Attach the listeners to `document`
      document.addEventListener('mousemove', this.mouseMoveHandler);
      document.addEventListener('mouseup', this.mouseUpHandler);
    },
    mouseMoveHandler(e) {
      // How far the mouse has been moved
      const dx = e.clientX - this.x;
      const dy = e.clientY - this.y;

      if (this.direction === 'vertical') {
        var h = ((this.prevSiblingHeight + dy) * 100) / this.$refs.resizer.parentNode.getBoundingClientRect().height;
        this.prevSibling.style.height = `${h}%`;
        document.body.style.cursor = 'row-resize';

      } else {
        var w = ((this.prevSiblingWidth + dx) * 100) / this.$refs.resizer.parentNode.getBoundingClientRect().width;
        this.prevSibling.style.width = `${w}%`;
        document.body.style.cursor = 'col-resize';
      }

      this.prevSibling.style.userSelect = 'none';
      this.prevSibling.style.pointerEvents = 'none';

      this.nextSibling.style.userSelect = 'none';
      this.nextSibling.style.userSelect = 'none';
      this.nextSibling.style.pointerEvents = 'none';
    },
    mouseUpHandler() {
      this.$refs.resizer.style.removeProperty('cursor');
      document.body.style.removeProperty('cursor');

      this.prevSibling.style.removeProperty('user-select');
      this.prevSibling.style.removeProperty('pointer-events');

      this.nextSibling.style.removeProperty('user-select');
      this.nextSibling.style.removeProperty('pointer-events');

      // Remove the handlers of `mousemove` and `mouseup`
      document.removeEventListener('mousemove', this.mouseMoveHandler);
      document.removeEventListener('mouseup', this.mouseUpHandler);
    }
  }
}

</script>

<style lang="scss">
.resizer[data-direction='horizontal'] {
  background-color: var(--color-primary-accent);
  cursor: col-resize;
  height: 100%;
  min-width: .8rem;
  width: .8rem;
}

.resizer[data-direction='vertical'] {
  background-color: var(--color-primary-accent);
  cursor: row-resize;
  height: 2rem;
  width: 100%;
  flex-shrink: 0;
}

/*
  Set the cursor and prevent text selection
  during drag.
*/
.resizer[data-direction='horizontal']:hover {
  cursor: col-resize;
}

.resizer[data-direction='vertical']:hover {
  cursor: row-resize;
}
</style>

<template>
  <div id="app" class="app">
    <div id="container" class="container">
      <pyodide ref="python_runner"></pyodide>
      <div class="codelet">
        <div class="top-pane">
          <h1 class="top-pane__title">Python Editor</h1>
          <div class="top-pane__buttons">
            <!-- commenting this out because it skips authentication -->
            <!-- <a :href="this.page" target="external-editor" class="external-link" v-if="isEmbbeded">Open in New Tab
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" width="1em" height="1em"><path d="M16 1H3v3H0v11h13v-3h3V1zm-4 13H1V7h2v5h9v2zm3-3H4V4h11v7z"></path></svg>
            </a> -->
            <button v-on:click="runButton" class="top-pane__button">
              Run
            </button>
            <button v-on:click="testButton" class="top-pane__button">
              Test
            </button>
          </div>
        </div>
        <editor ref="monaco_editor" @editor-initialized="initialise_editor" />
        <!-- Resizer Bar -->
        <resizer :direction="'vertical'" />
        <console-output :output="execution_output" @clearOutput="clearOutput" />
      </div>
    </div>
    <button v-if="plotFound" v-on:click="clearPlot" class="clear-plot-btn">
      <img src="./assets/bin-purple.svg" alt="Clear output" class="bottom-pane__clear-icon" height="15" width="15" />
    </button>
  </div>
</template>

<script>
import Editor from './components/Editor.vue';
import Pyodide from './components/Pyodide.vue';
import ConsoleOutput from './components/ConsoleOutput.vue';
import Resizer from './components/Resizer.vue';

export default {
  components: { Editor, Pyodide, ConsoleOutput, Resizer },
  name: 'App',
  data() {
    return {
      execution_output: null,
      initial_code: 'No code yet',
      test_code: 'No test code',
      page: '',
      plotFound: false,
      isEmbbeded: false
    };
  },
  async mounted() {
    console.log('App.vue - mounted start.');
    this.page = window.location.pathname.substring(1);
    console.log(this.page)
    this.isEmbbeded = window.location !== window.parent.location;
    console.log({ embbeded: this.isEmbbeded });
    console.log('App.vue - mounted end.');
  },
  async created() {
    console.log('App.vue - created start.');
    this.$on('editor-initialized', () => {
      console.log('On editor-initialized received - Child Component Editor has been initialized!');
      this.initialise_editor();
    });
    console.log('App.vue - created end.');
  },
  watch: {
    '$refs.Edtor.editor': {
      immediate: true,
      handler(newVal) {
        if (newVal !== null) {
          console.log('newVal is', newVal);
          console.log(
            'All components have been loaded and initialized and the dataValue of the editor component is not null',
          );
          // init your app here
        }
      },
    },
  },
  methods: {
    initialise_editor() {
      console.log('App.vue - Starting intialise editor');
      var lesson = this.page;
      if (lesson == '') {
        lesson = 'sample_exercise1';
      }

      var url = this.$config.lesson_root + lesson + '.py';
      console.log('App.vue - Loading lesson from ', url);

      function splitText(text) {
        const lines = text.split('\n');
        let code = '';
        let test = '';
        let found = false;

        for (let i = 0; i < lines.length; i++) {
          if (lines[i].includes('# ---')) {
            found = true;
            continue;
          }

          if (found) {
            test += lines[i] + '\n';
          } else {
            code += lines[i] + '\n';
          }
        }

        return [code, test];
      }

      fetch(url)
        .then(response => response.text())
        .then(data => {
          const [lesson, test] = splitText(data);
          console.log('Lesson is', lesson);
          console.log('Sending lesson to editor');
          this.$refs.monaco_editor.setValue(lesson);
          console.log('Done sending lesson to editor');
          this.test_code = test;
          console.log('Finished intialise editor');
        });
    },
    testButton() {
      console.log('App.Vue - Test button pressed');
      
      this.clearPlot();
      const code_to_run = this.$refs.monaco_editor.getValue() + '\n' + this.test_code;
      const execution_output = this.$refs.python_runner.runPython(code_to_run);
      this.printExecutionOutput(execution_output);
      this.checkPlotOutput();
      console.log('App.Vue - Test button finished');
    },
    runButton() {
      console.log('App.Vue - Run button pressed');
      this.clearPlot();
      const code_to_run = this.$refs.monaco_editor.getValue();
      const execution_output = this.$refs.python_runner.runPython(code_to_run);
      this.printExecutionOutput(execution_output);
      this.checkPlotOutput();
      console.log('Executon output:' + execution_output);
    },
    printExecutionOutput(execution_output) {
      this.execution_output = [this.execution_output, execution_output].join('\n').trim();
    },
    clearOutput() {
      this.execution_output = '';
    },
    clearPlot() {
      const element = document.querySelector('[id^="matplotlib_"]');
      if (element) {
        element.remove();
      }
      this.plotFound = false;
    },
    checkPlotOutput() {
      const element = document.querySelector('[id^="matplotlib_"]');
      this.plotFound = element != null;
    },
  },
};
</script>

<style lang="scss">
.external-link {
  color: var(--color-purple);
  display: flex;
  font-size: 16px;
  place-content: center;

  svg {
    margin-left: .5rem;
  }
}

.clear-plot-btn {
  background: var(--color-primary-accent);
  border-radius: 0.8rem;
  border: none;
  display: flex;
  margin-left: auto;
  margin-right: 1rem;
  margin-top: 1rem;
  padding: 0.6rem 1rem;
  place-content: center;
}

.app {
  width: 100%;
  height: 100%;
}

.top-pane {
  align-items: center;
  display: flex;
  background-color: var(--color-primary);
  padding: 1rem;
  justify-content: space-between;

  &__title {
    color: var(--color-primary-dark);
    font-size: 1.6rem;
    font-weight: 900;
    margin: 0;
  }

  &__buttons {
    display: flex;
    gap: 2rem;
    align-items: center;
  }

  &__button {
    background: var(--color-primary-accent);
    border: none;
    padding: 0.6rem 1.8rem;
    border-radius: 0.8rem;
    color: var(--color-white);
    box-shadow: 0.1rem 0.4rem 0.4rem rgba(0, 0, 0, 0.2138);
    font-weight: 600;
    font-size: 1.6rem;
    width: 7.5rem;

    &:hover {
      background: #afe036;
    }

    &:active {
      background: #7da027;
    }
  }
}

.container {
  height: 100%;
  margin: 0 auto;
  min-height: 50rem;
  min-width: 50rem;
  overflow: hidden;
  width: 100%;
}

.codelet {
  box-shadow: 0.1rem 0.4rem 0.4rem rgba(0, 0, 0, 0.2138);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
</style>

<template>
  <div id="editor" class="monaco-editor"></div>
</template>

<script>
import { listen } from 'vscode-ws-jsonrpc';

window.setImmediate = window.setTimeout;

import {
  MonacoLanguageClient,
  CloseAction,
  ErrorAction,
  createConnection,
  MonacoServices,
} from 'monaco-languageclient';

import loader from '@monaco-editor/loader';

export default {
  name: 'Editor',
  data() {
    return {
      editor: null,
      initial_text: 'Loading lesson...',
    };
  },

  async mounted() {
    const theme = {
      base: 'vs',
      inherit: true,

      colors: {
        'editor.background': '#F8FFE0',
      },
      rules: [
        { token: 'variable', foreground: 'b28c36' },
        { token: 'comment', foreground: '6e7781' },
        { token: 'keyword', foreground: '7c68cf' },
        { token: 'identifier', foreground: '4da1db' },
        { token: 'string', foreground: '00B5A2' },
        { token: 'number', foreground: '83b22a' },
        { token: 'operator', foreground: '912361' },
        { token: 'delimiter', foreground: '912361' },
      ],
    };

    loader.init().then(monaco => {
      monaco.editor.defineTheme('myTheme', theme);

      const editorOptions = {
        automaticLayout: true,
        fontFamily: 'Source Code Pro',
        fontSize: 14,
        fontWeight: '500',
        gutter: { enabled: false },
        language: 'python',
        minimap: { enabled: false },
        theme: 'myTheme',
        value: this.initial_text,
      };

      monaco.editor.create(document.getElementById('editor'), editorOptions);

      this.editor = monaco.editor;
      console.log('Emitting editor-initialized');
      this.$emit('editor-initialized');
      console.log('Editor.vue Editor initialised ');

      MonacoServices.install(monaco);
      this.connectToLangServer();
      console.log('Editor.vue Lang Server connected');
    });
    console.log('Editor.vue mounted end');
  },
  methods: {
    setValue: function (text) {
      console.log('Editor.vue setValue called');
      console.log('Text sent was', text);
      console.log('Editor is', this.editor);
      var editor = this.editor.getModels()[0];
      editor.setValue(text);
      console.log('Editor.vue setValue done');
    },
    getValue: function () {
      var editor = this.editor.getModels()[0];
      var value = editor.getValue();
      return value;
    },
    createLanguageClient: function (connection) {
      return new MonacoLanguageClient({
        name: 'Monaco language client',
        clientOptions: {
          documentSelector: ['python'],
          errorHandler: {
            error: () => ErrorAction.Continue,
            closed: () => CloseAction.Restart,
          },
        },

        connectionProvider: {
          get: (errorHandler, closeHandler) => {
            return Promise.resolve(createConnection(connection, errorHandler, closeHandler));
          },
        },
      });
    },
    connectToLangServer: function () {
      // TODO: Find a way to use the correct LSP Server depending on whether we are running locally or on the server.
      const webSocket = new WebSocket(`wss://${window.location.hostname}:8989`);

      listen({
        webSocket: webSocket,
        onConnection: connection => {
          var languageClient = this.createLanguageClient(connection);
          var disposable = languageClient.start();

          connection.onClose(function () {
            return disposable.dispose();
          });

          connection.onError(function (error) {
            console.error(error);
          });
        },
      });
    },
  },
};
</script>

<style lang="scss">
.monaco-editor {
  height: 60%;
  min-height: 5rem;
  width: 100%;
}
</style>

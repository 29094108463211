<script>
import { loadPyodide } from 'pyodide';
import Vue from "vue";
export default {
  name: 'Repl',
  data() {
    return {
      errorMsg: "No error",
      pyodideLoaded: false,
      execution_output: "Output here!",
      pyodide: null,
      term: {},
      repr_shorten: null,
      await_fut: null,
      pyconsole: null,
      clear_console: null,
      ps1: ">>> ",
      ps2: "... ",
    };
  },
  async mounted() {
    console.log("initializePyodide - about to load pyodide.js with Vue.loadScript()");
    await Vue.loadScript(
      "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/pyodide.js"
    );
    console.log("initializePyodide - loaded pyodide.js with Vue.loadScript()");

    console.log("initializePyodide - about to /pyodide/v0.21.3/full/ with Vue.loadScript()");
    this.pyodide = await loadPyodide({
      indexURL: "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/",
    });
    let namespace = this.pyodide.globals.get("dict")();
    this.pyodide.runPython(
      `
            import sys
            from pyodide.ffi import to_js
            from pyodide.console import PyodideConsole, repr_shorten
            import __main__
            pyconsole = PyodideConsole(__main__.__dict__)
            import builtins
            async def await_fut(fut):
              res = await fut
              if res is not None:
                builtins._ = res
              return to_js([res], depth=1)
            def clear_console():
              pyconsole.buffer = []
        `,
      { globals: namespace },
    );
    this.repr_shorten = namespace.get("repr_shorten");
    this.await_fut = namespace.get("await_fut");
    this.pyconsole = namespace.get("pyconsole");
    this.clear_console = namespace.get("clear_console");
    namespace.destroy();

    this.term = window.$('#replTerminal').terminal(this.interpreter, {
      prompt: this.ps1,
      greetings: '',
      completionEscape: false,
      completion: function (command, callback) {
        callback(this.pyconsole.complete(command).toJs()[0]);
      },
      keymap: {
        "CTRL+C": async function () {
          this.clear_console();
          this.term.enter();
          this.echo("KeyboardInterrupt");
          this.term.set_command("");
          this.term.set_prompt(this.ps1);
        },
        TAB: (event, original) => {
          const command = this.term.before_cursor();
          // Disable completion for whitespaces.
          if (command.trim() === "") {
            this.term.insert("\t");
            return false;
          }
          return original(event);
        },
      },
    });

    this.pyconsole.stdout_callback = (s) => this.echo(s, { newline: false });
    this.pyconsole.stderr_callback = (s) => {
      this.term.error(s.trimEnd());
    };
    this.term.ready = Promise.resolve();
    this.pyodide._api.on_fatal = async (e) => {
      if (e.name === "Exit") {
        this.term.error(e);
        this.term.error("Pyodide exited and can no longer be used.");
      } else {
        this.term.error(
          "Pyodide has suffered a fatal error. Please report this to the Pyodide maintainers.",
        );
        this.term.error("The cause of the fatal error was:");
        this.term.error(e);
        this.term.error("Look in the browser console for more details.");
      }
      await this.term.ready;
      this.term.pause();
      await this.sleep(15);
      this.term.pause();
    };
    this.term.focus(false);
    window.term = this.term;
  },
  methods: {
    clearConsole() {
      this.term.clear();
    },
    sleep(s) { return new Promise((resolve) => setTimeout(resolve, s)) },
    echo(msg, ...opts) {
      return this.term['echo'](
        msg
          .replaceAll("]]", "&rsqb;&rsqb;")
          .replaceAll("[[", "&lsqb;&lsqb;"),
        ...opts,
      )
    },
    async lock() {
      let resolve;
      let ready = this.term.ready;
      this.term.ready = new Promise((res) => (resolve = res));
      await ready;
      return resolve;
    },
    async interpreter(command) {
      let unlock = await this.lock();
      this.term.pause();
      // multiline should be split (useful when pasting)
      for (const c of command.split("\n")) {
        const escaped = c.replaceAll(/\u00a0/g, " ");
        let fut = this.pyconsole.push(escaped);
        this.term.set_prompt(fut.syntax_check === "incomplete" ? this.ps2 : this.ps1);
        switch (fut.syntax_check) {
          case "syntax-error":
            this.term.error(fut.formatted_error.trimEnd());
            continue;
          case "incomplete":
            continue;
          case "complete":
            break;
          default:
            throw new Error(`Unexpected type ${this.ty}`);
        }
        // In JavaScript, await automatically also awaits any results of
        // awaits, so if an async function returns a future, it will await
        // the inner future too. This is not what we want so we
        // temporarily put it into a list to protect it.
        let wrapped = this.await_fut(fut);
        // complete case, get result / error and print it.
        try {
          let [value] = await wrapped;
          if (value !== undefined) {
            this.echo(
              this.repr_shorten.callKwargs(value, {
                separator: "\n<long output truncated>\n",
              }),
            );
          }
          if (this.pyodide.isPyProxy(value)) {
            value.destroy();
          }
        } catch (e) {
          if (e.constructor.name === "PythonError") {
            const message = fut.formatted_error || e.message;
            this.term.error(message.trimEnd());
          } else {
            throw e;
          }
        } finally {
          fut.destroy();
          wrapped.destroy();
        }
      }
      this.term.resume();
      await this.sleep(10);
      unlock();
    }
  }
}
</script>

<template>
  <div id="replTerminal" class="repl-body">
  </div>
</template>

<style>
.terminal {
  --size: 1.2;
  --color: var(--color-purple);
  --font: 'Source Code Pro';
}

.terminal-scroller::-webkit-scrollbar {
  background: rgba(0,0,0,0.2);
}

.terminal-scroller {
  --color: rgba(0,0,0,0.2);
  padding: .2rem .2rem .2rem 1rem;
  overflow-x: hidden;
  white-space: nowrap;
}

.noblink {
  --animation: terminal-none;
}

.repl-body {
  background-color: var(--color-primary);
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  border: 3px solid rgba(172, 237, 255, 0.5);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>

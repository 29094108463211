import Vue from 'vue'
import App from './App.vue'

import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

Vue.config.productionTip = false


fetch(process.env.BASE_URL + "config.json")
  .then((response) => response.json())
  .then((config) => {
       Vue.prototype.$config = config
       new Vue({
         // router,
         // store,
         render: (h) => h(App)
       }).$mount("#app")
  })

// new Vue({
//  render: h => h(App),
//}).$mount('#app')

export const eventBus=new Vue()

